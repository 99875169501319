const headerData = [
  {
    text: 'Medicare Plans',
    subnav: [
      { text: 'All Plans', href: '/plans' },
      { text: 'Medicare Part A', href: '/parta' },
      {
        text: 'Medicare Part B',
        href: '/medicare-part-b',
        subnav: [{ text: 'Detail Page', href: '/medicare-part-b/details' }],
      },
      { text: 'Medicare Advantage', href: '/advantage' },
      { text: 'Medicare PPO', href: '/ppo' },
      { text: 'Medicare HMO', href: '/hmo' },
    ],
  },
  {
    text: 'Medicare Resources',
    subnav: [
      { text: 'Medicare Eligibility', href: '/resources/eligibility' },
      { text: 'Medicare Enrollment Periods', href: '/enrollment' },
      { text: 'Medicare ID Card', href: '/medicare-card' },
      { text: 'FAQ', href: '/faq' },
    ],
  },
  { text: 'Locations', href: '/locations' },
  { text: 'Apply for Medicare', href: '/apply' },
]

export default headerData

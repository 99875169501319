/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import utahHero from '../../images/locationsHeroes/hero-utah.jpg'
import womanLaptopEnroll from '../../images/people/woman-laptop-enroll.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import DynamicCTACard from '../../components/DynamicCTACard'
import SeoCtaBar from '../../components/SeoCtaBar'

import CobraBanner from '../../components/CobraBanner'

import {
  Accordion,
  Columns,
  Column,
  Hero,
  Link,
  LinkButton,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/Footer'
import header from '../../components/Header'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()
  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-plan',
    'disclaimer-evidence',
    'dsiclaimer-changenotice',
    'disclaimer-aetnabrand',
    'disclaimer-clicopyright',
    'disclaimer-incompletedescrip',
    'disclaimer-cmsmaterialid',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: ' Apply for Aetna Medicare Plans in Utah | Call 833-998-1009 ',
          description:
            'Learn more about Aetna Medicare plans in Utah. Let us help you find the right plan and help answer any questions you might have. Call 833-998-1009',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/utah',
        promoCode: '151581',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      ' Apply for Aetna Medicare Plans in Utah | Call 833-998-1009 ',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={utahHero}
                  alt="elderly couple at Arches National Park"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1" color="light">
                    Aetna<sup>®</sup> Medicare in Utah{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>

        <SeoCtaBar />

        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Aetna Medicare Insurance for Utahns
              </Typography>
              <Typography variant="body">
                Once you enroll in a Medicare plan with Aetna, you’ll be joining
                the over 380,000 Utahns already part of the Medicare family.*
                Now you can visit Arches National Park and enjoy the red rocks
                without having to worry about your next medical expense. Get
                Aetna Medicare coverage, sit back, and enjoy a bowl of green
                jello—you’ve earned it.
              </Typography>
              <StaticImage
                src="../../images/illustrations/utah-stats.svg"
                alt="More than 380,000 people in Utah are enrolled in a Medicare plan.*"
                layout="fixed"
                className="product-icons"
              />
              <Typography variant="h5" color="primary">
                More than 380,000 people in Utah are enrolled in a Medicare
                plan.*
              </Typography>
              <Typography variant="legal">
                *Kaiser Family Foundation{' '}
                <a
                  href="https://www.kff.org/medicare/state-indicator/total-medicare-beneficiaries/?currentTimeframe=0&sortModel=%7B%22colId%22:%22Location%22,%22sort%22:%22asc%22%7D"
                  target="_blank"
                >
                  https://www.kff.org/medicare/state-indicator/total-medicare-beneficiaries/
                </a>
              </Typography>
              <Typography variant="body">
                To learn more about the available Medicare benefits in your
                area, call to talk to a licensed agent about Aetna Medicare.
              </Typography>
            </>
          }
        ></VariableContent>

        <CobraBanner />

        <VariableContent
          backgroundColor="lightGray"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Types of Medicare plans in the Beehive State
              </Typography>
              <Typography variant="body">
                Don’t miss out on next year’s Sundance Film Festival. With so
                many movies to watch, you’ll be sure to have a lively and
                enjoyable time. Medicare plans allow you to have a great time
                too, when the coverage is right. An Aetna Medicare plan will
                keep you moving in the right direction.
              </Typography>
            </>
          }
        >
          <Stack>
            <Columns>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-health.svg"
                    alt="health icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Advantage (Part C)
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/advantage"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-dollar.svg"
                    alt="dollar icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare HMO
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/hmo"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-hospital.svg"
                    alt="hospital icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare PPO
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/ppo"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-heart.svg"
                alt="heart icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                Am I eligible for Medicare in Utah?
              </Typography>
              <Typography variant="body">
                Most Utahns who are 65 or older and legal US residents are
                eligible for Medicare. Some residents under 65 with certain
                health conditions may be eligible as well. Original Medicare
                (Parts A and B) gives you plenty of coverage, but for those
                seeking more benefits, Medicare Advantage plans have you
                covered. The plans may have vision, dental, hearing and
                prescription drug coverage.
              </Typography>
              <Typography variant="body">
                Medicare’s varied options have different levels of eligibility.
                If you have any questions or concerns regarding your eligibility
                for Medicare plans, including Aetna Medicare Advantage, make the
                call. A friendly, licensed agent can help.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent backgroundColor="lightGray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-calendar.svg"
                alt="calendar icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">When can I enroll?</Typography>
              <Typography variant="body">
                There are three primary Medicare enrollment periods:
              </Typography>
              <Typography variant="h5">Initial Enrollment Period</Typography>
              <Typography variant="body">
                If you’re turning 65 soon and enrolling in Medicare for the
                first time, you have the month of your 65th birthday and a
                three-month grace period before and after your birthday known as
                the Initial Enrollment Period.
              </Typography>
              <Typography variant="h5">Annual Enrollment Period</Typography>
              <Typography variant="body">
                Already have Original Medicare and want to upgrade your coverage
                by switching to a Medicare Advantage plan? Make the switch
                during the Annual Enrollment Period which starts October 15th
                and ends December 7th every year.
              </Typography>
              <Typography variant="h5">Special Enrollment Period</Typography>
              <Typography variant="body">
                Under some circumstances, you may be able to enroll in a
                Medicare plan during a Special Enrollment Period. For example,
                you may be able to enroll in coverage or switch plans if you’ve
                recently moved to an area where your current plan is not
                covered. Talk to one of our licensed agents today to see if your
                special circumstance applies.
              </Typography>
              <Typography variant="h4" color="primary">
                Get enrolled in three simple steps:
              </Typography>
            </Column>
          </Columns>
          <Stack>
            <Columns>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-phone.svg"
                    alt="phone icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Call a licensed agent
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-checklist.svg"
                    alt="checklist icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Answer some questions about your coverage needs
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-card.svg"
                    alt="card icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Sign up for a plan
                  </Typography>
                </div>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-magnify.svg"
                alt="magnifying glass icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                Other Medicare Resources in Utah
              </Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title:
                        'Utah Department of Human Services: Aging and Adult Services',
                      content: (
                        <>
                          <Typography>
                            Utah’s robust{' '}
                            <a
                              href="https://www.daas.utah.gov/seniors/"
                              target="_blank"
                            >
                              Department of Human Services: Aging and Adult
                              Services
                            </a>{' '}
                            has multiple resources to help you stay healthy,
                            stay fed, and pay for your Medicare expenses.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Adult Protective Services',
                      content: (
                        <>
                          <Typography>
                            If you’re concerned about fraud or abuse, you can
                            report it to the{' '}
                            <a
                              href="https://utahaps.com/LeapsIntake/NewPublicIntakeReport_301.aspx"
                              target="_blank"
                            >
                              Adult Protective Services
                            </a>
                            .
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Governor’s Century Club of Utah',
                      content: (
                        <>
                          <Typography>
                            Join the{' '}
                            <a
                              href="https://daas.utah.gov/centenarians/"
                              target="_blank"
                            >
                              Governor’s Century Club of Utah
                            </a>{' '}
                            when you reach 100 years old and get memorialized in
                            its yearbook!
                          </Typography>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
            </Column>
          </Columns>
        </VariableContent>

        <SplitContent
          backgroundColor="lightGray"
          alignImageToBottom={false}
          image={
            <img
              src={womanLaptopEnroll}
              alt="an elderly woman enrolls in Medicare on her laptop"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Enroll in a Medicare Plan in Utah today!
              </Typography>
              <Typography variant="body">
                Ready to enroll in a Medicare plan? Tap or click below to get
                started. If you need help during the enrollment process, contact
                a friendly licensed agent at{' '}
                <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link>. (TTY:
                711, available 24/7)
              </Typography>
              <div className="card bg-light">
                <Typography variant="h4">
                  Request your complimentary Medicare insurance quote{' '}
                </Typography>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="primary"
                  className="margin-x-auto"
                >
                  Get Quote
                </LinkButton>
              </div>
            </>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`

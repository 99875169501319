import React from 'react'
import {
  Banner,
  LeshenPhoneCTA,
  Typography,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Phone } from 'emotion-icons/material'

const CobraBanner = () => {
  const { rotatedNumber, requestId } = useMapi()

  return (
    <Banner
      backgroundColor="Primary"
      centerAligned
      layout="100"
      className="cta-banner"
      mainContent={
        <>
          <Typography
            variant="h4"
            style={{ color: 'white', marginBottom: '8px' }}
          >
            Talk to a Licensed Agent or View Plans Online
          </Typography>
          <Typography>TTY:711, available 24/7</Typography>
          <LeshenPhoneCTA
            variant=""
            cobra="Hide CTA"
            to={`tel:${rotatedNumber}`}
            color="light"
            leftIcon={<Phone />}
            requestId={requestId}
          />
        </>
      }
    />
  )
}

export default CobraBanner
